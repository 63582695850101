/*
 * @Author: fan.lion@foxmail.com
 * @Date: 2019-09-16 19:38:40
 * @Last Modified by: fan.lion@foxmail.com
 * @Last Modified time: 2019-09-22 23:41:03
 *
 * 联系页
 */
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl'
import { useSiteMetadata } from '../hooks'
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import style from '../styles/contact.module.css';

const ContactPage = () => {
  const intl = useIntl()
  const site = useSiteMetadata()

  // 提交表单
  const onSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('contact-form');
    const name = form.name.value;
    const email = form.email.value;
    const phone = form.phone.value;
    const company = form.company.value;
    const scale = form.scale.value;
    const remark = form.remark.value;

    const params = { name, email, phone, company, scale, remark };

    if (name === '' || email === '' || phone === '' || company === '' || scale === '') {
      return alert(intl.formatMessage({ id: '请填写必填项再提交表单' }));
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
          form.reset();
          alert(intl.formatMessage({ id: '您的信息已保存，我们的销售人员将会尽快联系您' }));
        } else {
          alert(intl.formatMessage({ id: '抱歉，提交失败，请稍后再试' }));
        }
      }
    };

    xhr.open('post', 'https://console.teampro.work/api/contact', true);
    xhr.setRequestHeader('content-type', 'application/json');
    xhr.send(JSON.stringify(params));
  }

  return (
    <Layout activeNav="contact">
      <SEO
        title={intl.formatMessage({ id: '联系我们' })}
        lang={intl.locale}
      />

      <div className={style.main}>
        <h1 className={style.title}>{intl.formatMessage({ id: '联系销售人员' })}</h1>
        <div className={style.sub_title}>{intl.formatMessage({ id: '请输入您的联系信息' })}</div>

        <form id="contact-form" onSubmit={onSubmit} noValidate>
          <section className={style.row}>
            <div className={style.input}>
              <label htmlFor="name">{intl.formatMessage({ id: '姓名' })}<span>*</span></label>
              <input name="name" type="text" required />
            </div>
            <div className={style.input}>
              <label htmlFor="tel">{intl.formatMessage({ id: '手机号' })}<span>*</span></label>
              <input name="phone" type="tel" required />
            </div>
          </section>

          <section className={style.row}>
            <div className={style.input}>
              <label htmlFor="email">{intl.formatMessage({ id: '邮箱' })}<span>*</span></label>
              <input name="email" type="email" required />
            </div>
          </section>

          <section className={style.row}>
            <div className={style.input}>
              <label htmlFor="company">{intl.formatMessage({ id: '公司名称' })}<span>*</span></label>
              <input name="company" required />
            </div>

            <div className={style.select}>
              <label htmlFor="scale">{intl.formatMessage({ id: '公司规模' })}<span>*</span></label>
              <div className={style.select_box}>
                <select name="scale" required>
                  <option value="0-50">{intl.formatMessage({ id: '0-50人' })}</option>
                  <option value="50-200">{intl.formatMessage({ id: '51-200人' })}</option>
                  <option value="201-1000">{intl.formatMessage({ id: '201-1000人' })}</option>
                  <option value="1000+">{intl.formatMessage({ id: '1000人以上' })}</option>
                </select>
              </div>
            </div>
          </section>

          <section>
            <div className={style.textarea}>
              <label htmlFor="remark">{intl.formatMessage({ id: '与您业务需求相关的其他信息' })}</label>
              <textarea rows={8} name="remark"></textarea>
            </div>
          </section>

          <section className={style.submit}>
            <button type="submit">
              <span>{intl.formatMessage({ id: '提交' })}</span>
            </button>
          </section>
        </form>

        <div className={style.contact_box}>
          <h2>{intl.formatMessage({ id: '您还可以通过以下方式联系我们' })}：</h2>
          {!!site.bd_phone && (
            <div className={style.contact_item}>
              <label htmlFor="contact_tel">{intl.formatMessage({ id: '销售电话' })}：</label>
              <a name="contact_tel" href={`tel:${site.bd_phone}`}>{site.bd_phone}</a>
            </div>
          )}
        </div>

        {!!site.bd_email && (
          <div className={style.contact_item}>
            <label htmlFor="contact_mail">{intl.formatMessage({ id: '销售邮箱' })}：</label>
            <a name="contact_mail" href={`mailto:${site.bd_email}`}>{site.bd_email}</a>
          </div>
        )}

        {!!site.bd_address && (
          <div className={style.contact_item}>
            <label htmlFor="contact_address">{intl.formatMessage({ id: '联系地址' })}：</label>
            <span name="contact_address">{site.bd_address}</span>
          </div>
        )}

        {/* 背景三角形 */}
        <div className={style.triangle1}></div>
        <div className={style.triangle2}></div>
        <div className={style.triangle3}></div>
      </div>
    </Layout>
  );
};

export default ContactPage;
